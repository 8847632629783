angular.module('app.directives', [])  

  .directive('scrollPage', ->
    restrict: 'A'
    link : (scope, element, attrs) ->
      element.on 'click', (e) ->
        e.preventDefault()
        offset = if element.hasClass('nav-link-page') then 50 else 0
        scrollPage = new SmoothScroll()
        scrollPage.animateScroll(document.querySelector($(this).attr('href')), 0, {updateURL: false, header: '.header-placeholder', offset: offset, speed: 200, speedAsDuration: true})
  )
  
  .directive('scrollbar', ->
    restrict: 'A'
    link : (scope, element, attrs) ->
      element.scrollbar()
  )

  .directive('atooltip', ->
    restrict: 'A'
    link: (scope, element, attrs) ->
      tooltip = new bootstrap.Tooltip(element, {
        customClass: 'atooltip'
        container: $(element)
        trigger: 'hover focus click'
        placement: 'bottom'
      })
  )

  .directive('scrollspy', ->
    restrict: 'A'
    link: (scope, element, attrs) ->
      scrollSpy = new bootstrap.ScrollSpy(document.body, {
        target: '#page-nav'
        offset: 115
      })
  )

  .directive('countdown', ->
    restrict: 'A'
    scope:
      event_start: '=countdown'
    link : (scope, element, attrs) ->
      start = moment(scope.event_start).format('YYYY/MM/DD HH:mm:ss')      
      element.countdown start, (event) ->
        $('#countdown-d').html(event.strftime('%D'))
        $('#countdown-h').html(event.strftime('%H'))
        $('#countdown-m').html(event.strftime('%M'))
        $('#countdown-s').html(event.strftime('%S'))
  )


  .directive('favcount', ->
    restrict: 'A'
    scope:
      count: '=favcount'
    link: (scope, element, attrs) ->
      scope.$watch 'count', (newVal, oldVal) ->
        element.find('.animate').shake()
      , true
  )
  
  .directive('modalClose', ->
    restrict: 'A'
    link : (scope, element, attrs) ->
      modalId = element.closest('.modal').attr('id')
      $modal = $("##{modalId}")

      element.on 'click', (e) ->
        e.preventDefault()
        $modalVideo = $modal.find(".modal-video-src")
        $modalVideo.attr("src", '')
        $modal.modal('hide')

        scope.$applyAsync()
  )

  .directive "compareTo", () ->
    require: "ngModel"
    scope:  
      confirmPassword: "=compareTo"
    link: (scope, element, attributes, modelVal) ->
      modelVal.$validators.compareTo = (val) ->
        return true unless val
        return val == scope.confirmPassword
      scope.$watch "confirmPassword", () ->
        modelVal.$validate()

  .directive('fileModel', ['$parse', ($parse) ->
    return
      restrict: 'A'
      link: (scope, element, attrs) ->
        model = $parse(attrs.fileModel)
        modelSetter = model.assign
        
        element.bind 'change', () ->
          scope.$apply () ->
            modelSetter(scope, element[0].files[0])

  ])
  

  .directive 'onErrorSrc', () ->
    return
      link: (scope, element, attrs) ->
        element.bind 'error', () ->
          if (attrs.src != attrs.onErrorSrc)
            attrs.$set('src', attrs.onErrorSrc)
        
  .directive('myEnter', () ->
    return (scope, element, attrs) ->
      element.bind("keydown keypress",  (event) ->
        if (event.which == 13)
          scope.$apply(() ->
            scope.$eval(attrs.myEnter)
          )
          event.preventDefault()        
      )
  )

  .directive('floatClose', ->
    restrict: 'A'
    link : (scope, element, attrs) ->
      fvID = element.closest('.fv').attr('id')
      $fv = $("##{fvID}")

      element.on 'click', (e) ->
        $floatVideo = $fv.find(".ritem")
        src = $floatVideo.attr("src")
        $floatVideo.attr("src", '')
        #$floatVideo.attr("src", src)
        scope.$applyAsync()
  )

  .directive "validEmail", () ->
    require: "ngModel"
    scope:  
      email: "=validEmail"
    link: (scope, element, attributes, modelVal) ->
      modelVal.$validators.validEmail = (val) ->
        return false unless val
        return scope.email

      scope.$watch "email", () ->
        modelVal.$validate()


  .directive('paginationFor', ->
    restrict: 'A'
    template: '
      <nav ng-show="table.data.pages > 1">
        <ul class="pagination jc-c gap-12 fw-wrap">
          <li class="page-item" ng-class="{disabled: table.params.page == 1}"><a class="page-link" aria-label="previous" href rel="noffolow" ng-click="table.setPage(table.params.page - 1)"><svg class="fi fi-16 d-inline-block"><use xlink:href="assets/img/icons/feather-sprite.svg#chevron-left"/></svg></a></li>
          <li class="page-item" ng-repeat="page in table.pagination track by $index" ng-class="{active: table.params.page == page, disabled: !page}">
            <a href rel="noffolow" class="page-link" ng-click="table.setPage(page)" ng-show="page">{{ page }}</a>
            <a href rel="noffolow" class="page-link" ng-hide="page">&hellip;</a>
          </li>
          <li class="page-item" ng-class="{disabled: table.params.page == table.data.pages}"><a class="page-link" href rel="noffolow" aria-label="next" ng-click="table.setPage(table.params.page + 1)"><svg class="fi fi-16 d-inline-block"><use xlink:href="assets/img/icons/feather-sprite.svg#chevron-right"/></svg></a></li>
        </ul>         
      </nav>'
    replace: true
    scope:
      table: '=paginationFor'
    controller: ['$scope', '$filter', (self, $filter) ->
      return
    ]
  ) 

  .directive('appointments', ['$window', '$rootScope', 'APITimes', '$location', ($window, root, APITimes, $location) ->
    restrict: 'A'
    template: '
      <div class="aus-footer" ng-show="isHasTimes()">
        <div class="aus-time" ng-class="{\'active\': booked}">
          <div ng-hide="booked">Make an appointment!</div>
          <div ng-show="booked">
            <div class="opacity-50">Your appointment:</div>
            {{ booked.display_3 }}
          </div>
          <span class="ic" ng-class="{\'ic-add ic-add-check active\': booked}"><svg class="fi"><use xlink:href="assets/img/icons/feather-sprite.svg#calendar"/></svg></span>
          <a href rel="noffolow" class="stretched-link" ng-click="openTimeModal()"></a>
        </div>
      </div>
    '
    replace: true
    scope:
      exhibitor: '=appointments'
      random: '='
    link : (self, element, attrs) ->      

      self.times = []
      getData = () ->
        #return unless root.user?.auth_ticket?
        ticket = root.user?.auth_ticket || 'tNDIyLy8xNTc1NTc1Ly8w'
        times = APITimes.query {_entry: 'times', exhibitor_id: self.exhibitor, auth_ticket: ticket}, (data) ->
          self.times  = angular.copy(times)
          self.booked = false
          self.booked = self.times.filter((item) -> return item.booked)[0] || false
        return
      
      self.isHasTimes = () ->
        return root.user?.auth_ticket? && self.times.length > 0

      self.openTimeModal = () ->
        root.$broadcast('showTimeModal', self.times, 'updateListTime')
       
      self.$watch 'random', (oldObj, newObj) ->
        getData()
      , true 

      return 
  ])

  .directive('datepicker', ['$cookies', '$rootScope', ($cookies, root) ->
    restrict: 'A'
    require: 'ngModel'
    scope:
      theme: '='
      time: '='

    link: (self, element, attrs, ngModelCtrl) ->
      
      #timezone = root.setting('timezone') || 'Europe/Vienna'
      appTime  = $cookies.get("app_time") || moment().format()

      element.datetimepicker
        dayOfWeekStart: 1
        format: if !!self.time then 'H:i' else 'd/m/Y'
        lang: 'en'
        timepicker: !!self.time
        datepicker: !!!self.time
        closeOnDateSelect: true
        mask: if !!self.time then '99:99' else '39/19/9999'
        theme: self.theme
        onChangeDate: (dp, $input) ->
          self.$apply ->
            ngModelCtrl.$setViewValue(dp)

  ])


  .directive('datepickerr', ['$cookies', '$rootScope', ($cookies, root) ->
    restrict: 'A'
    scope:
      theme: '='
      time: '='

    link: (self, element, attrs) ->
      
      getTime = () ->
        return $cookies.get("app_time") || moment().format('DD/MM/YYYY kk:mm')


      element.datetimepicker
        dayOfWeekStart: 1
        format: 'd/m/Y H:i'
        formatTime: 'H:i'
        formatDate: 'd/m/Y'
        lang: 'en'
        timepicker: !!self.time
        datepicker: !!!self.time
        closeOnDateSelect: false
        theme: self.theme
        todayButton: true
        
        onChangeDateTime: (dp, $input) ->
          return unless dp
          self.$apply ->
            $cookies.put("app_time", moment(dp).format('DD/MM/YYYY kk:mm'))
            root.$broadcast 'fetchTime'

        onShow: (ct) ->        
          dt = getTime()
          this.setOptions({
            defaultDate: moment(dt, 'DD/MM/YYYY kk:mm').format('DD/MM/YYYY')
            defaultTime: moment(dt, 'DD/MM/YYYY kk:mm').format('kk:mm')
          })

  ])
angular.module('app.directives', []).directive('scrollPage', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      return element.on('click', function(e) {
        var offset, scrollPage;
        e.preventDefault();
        offset = element.hasClass('nav-link-page') ? 50 : 0;
        scrollPage = new SmoothScroll();
        return scrollPage.animateScroll(document.querySelector($(this).attr('href')), 0, {
          updateURL: false,
          header: '.header-placeholder',
          offset: offset,
          speed: 200,
          speedAsDuration: true
        });
      });
    }
  };
}).directive('scrollbar', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      return element.scrollbar();
    }
  };
}).directive('atooltip', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      var tooltip;
      return tooltip = new bootstrap.Tooltip(element, {
        customClass: 'atooltip',
        container: $(element),
        trigger: 'hover focus click',
        placement: 'bottom'
      });
    }
  };
}).directive('scrollspy', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      var scrollSpy;
      return scrollSpy = new bootstrap.ScrollSpy(document.body, {
        target: '#page-nav',
        offset: 115
      });
    }
  };
}).directive('countdown', function() {
  return {
    restrict: 'A',
    scope: {
      event_start: '=countdown'
    },
    link: function(scope, element, attrs) {
      var start;
      start = moment(scope.event_start).format('YYYY/MM/DD HH:mm:ss');
      return element.countdown(start, function(event) {
        $('#countdown-d').html(event.strftime('%D'));
        $('#countdown-h').html(event.strftime('%H'));
        $('#countdown-m').html(event.strftime('%M'));
        return $('#countdown-s').html(event.strftime('%S'));
      });
    }
  };
}).directive('favcount', function() {
  return {
    restrict: 'A',
    scope: {
      count: '=favcount'
    },
    link: function(scope, element, attrs) {
      return scope.$watch('count', function(newVal, oldVal) {
        return element.find('.animate').shake();
      }, true);
    }
  };
}).directive('modalClose', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      var $modal, modalId;
      modalId = element.closest('.modal').attr('id');
      $modal = $(`#${modalId}`);
      return element.on('click', function(e) {
        var $modalVideo;
        e.preventDefault();
        $modalVideo = $modal.find(".modal-video-src");
        $modalVideo.attr("src", '');
        $modal.modal('hide');
        return scope.$applyAsync();
      });
    }
  };
}).directive("compareTo", function() {
  return {
    require: "ngModel",
    scope: {
      confirmPassword: "=compareTo"
    },
    link: function(scope, element, attributes, modelVal) {
      modelVal.$validators.compareTo = function(val) {
        if (!val) {
          return true;
        }
        return val === scope.confirmPassword;
      };
      return scope.$watch("confirmPassword", function() {
        return modelVal.$validate();
      });
    }
  };
}).directive('fileModel', [
  '$parse',
  function($parse) {
    return {
      restrict: 'A',
      link: function(scope,
  element,
  attrs) {
        var model,
  modelSetter;
        model = $parse(attrs.fileModel);
        modelSetter = model.assign;
        return element.bind('change',
  function() {
          return scope.$apply(function() {
            return modelSetter(scope,
  element[0].files[0]);
          });
        });
      }
    };
  }
]).directive('onErrorSrc', function() {
  return {
    link: function(scope, element, attrs) {
      return element.bind('error', function() {
        if (attrs.src !== attrs.onErrorSrc) {
          return attrs.$set('src', attrs.onErrorSrc);
        }
      });
    }
  };
}).directive('myEnter', function() {
  return function(scope, element, attrs) {
    return element.bind("keydown keypress", function(event) {
      if (event.which === 13) {
        scope.$apply(function() {
          return scope.$eval(attrs.myEnter);
        });
        return event.preventDefault();
      }
    });
  };
}).directive('floatClose', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      var $fv, fvID;
      fvID = element.closest('.fv').attr('id');
      $fv = $(`#${fvID}`);
      return element.on('click', function(e) {
        var $floatVideo, src;
        $floatVideo = $fv.find(".ritem");
        src = $floatVideo.attr("src");
        $floatVideo.attr("src", '');
        //$floatVideo.attr("src", src)
        return scope.$applyAsync();
      });
    }
  };
}).directive("validEmail", function() {
  return {
    require: "ngModel",
    scope: {
      email: "=validEmail"
    },
    link: function(scope, element, attributes, modelVal) {
      modelVal.$validators.validEmail = function(val) {
        if (!val) {
          return false;
        }
        return scope.email;
      };
      return scope.$watch("email", function() {
        return modelVal.$validate();
      });
    }
  };
}).directive('paginationFor', function() {
  return {
    restrict: 'A',
    template: '<nav ng-show="table.data.pages > 1"> <ul class="pagination jc-c gap-12 fw-wrap"> <li class="page-item" ng-class="{disabled: table.params.page == 1}"><a class="page-link" aria-label="previous" href rel="noffolow" ng-click="table.setPage(table.params.page - 1)"><svg class="fi fi-16 d-inline-block"><use xlink:href="assets/img/icons/feather-sprite.svg#chevron-left"/></svg></a></li> <li class="page-item" ng-repeat="page in table.pagination track by $index" ng-class="{active: table.params.page == page, disabled: !page}"> <a href rel="noffolow" class="page-link" ng-click="table.setPage(page)" ng-show="page">{{ page }}</a> <a href rel="noffolow" class="page-link" ng-hide="page">&hellip;</a> </li> <li class="page-item" ng-class="{disabled: table.params.page == table.data.pages}"><a class="page-link" href rel="noffolow" aria-label="next" ng-click="table.setPage(table.params.page + 1)"><svg class="fi fi-16 d-inline-block"><use xlink:href="assets/img/icons/feather-sprite.svg#chevron-right"/></svg></a></li> </ul> </nav>',
    replace: true,
    scope: {
      table: '=paginationFor'
    },
    controller: ['$scope', '$filter', function(self, $filter) {}]
  };
}).directive('appointments', [
  '$window',
  '$rootScope',
  'APITimes',
  '$location',
  function($window,
  root,
  APITimes,
  $location) {
    return {
      restrict: 'A',
      template: '<div class="aus-footer" ng-show="isHasTimes()"> <div class="aus-time" ng-class="{\'active\': booked}"> <div ng-hide="booked">Make an appointment!</div> <div ng-show="booked"> <div class="opacity-50">Your appointment:</div> {{ booked.display_3 }} </div> <span class="ic" ng-class="{\'ic-add ic-add-check active\': booked}"><svg class="fi"><use xlink:href="assets/img/icons/feather-sprite.svg#calendar"/></svg></span> <a href rel="noffolow" class="stretched-link" ng-click="openTimeModal()"></a> </div> </div>',
      replace: true,
      scope: {
        exhibitor: '=appointments',
        random: '='
      },
      link: function(self,
  element,
  attrs) {
        var getData;
        self.times = [];
        getData = function() {
          var ref,
  ticket,
  times;
          //return unless root.user?.auth_ticket?
          ticket = ((ref = root.user) != null ? ref.auth_ticket : void 0) || 'tNDIyLy8xNTc1NTc1Ly8w';
          times = APITimes.query({
            _entry: 'times',
            exhibitor_id: self.exhibitor,
            auth_ticket: ticket
          },
  function(data) {
            self.times = angular.copy(times);
            self.booked = false;
            return self.booked = self.times.filter(function(item) {
              return item.booked;
            })[0] || false;
          });
        };
        self.isHasTimes = function() {
          var ref;
          return (((ref = root.user) != null ? ref.auth_ticket : void 0) != null) && self.times.length > 0;
        };
        self.openTimeModal = function() {
          return root.$broadcast('showTimeModal',
  self.times,
  'updateListTime');
        };
        self.$watch('random',
  function(oldObj,
  newObj) {
          return getData();
        },
  true);
      }
    };
  }
]).directive('datepicker', [
  '$cookies',
  '$rootScope',
  function($cookies,
  root) {
    return {
      restrict: 'A',
      require: 'ngModel',
      scope: {
        theme: '=',
        time: '='
      },
      link: function(self,
  element,
  attrs,
  ngModelCtrl) {
        var appTime;
        
        //timezone = root.setting('timezone') || 'Europe/Vienna'
        appTime = $cookies.get("app_time") || moment().format();
        return element.datetimepicker({
          dayOfWeekStart: 1,
          format: !!self.time ? 'H:i' : 'd/m/Y',
          lang: 'en',
          timepicker: !!self.time,
          datepicker: !!!self.time,
          closeOnDateSelect: true,
          mask: !!self.time ? '99:99' : '39/19/9999',
          theme: self.theme,
          onChangeDate: function(dp,
  $input) {
            return self.$apply(function() {
              return ngModelCtrl.$setViewValue(dp);
            });
          }
        });
      }
    };
  }
]).directive('datepickerr', [
  '$cookies',
  '$rootScope',
  function($cookies,
  root) {
    return {
      restrict: 'A',
      scope: {
        theme: '=',
        time: '='
      },
      link: function(self,
  element,
  attrs) {
        var getTime;
        getTime = function() {
          return $cookies.get("app_time") || moment().format('DD/MM/YYYY kk:mm');
        };
        return element.datetimepicker({
          dayOfWeekStart: 1,
          format: 'd/m/Y H:i',
          formatTime: 'H:i',
          formatDate: 'd/m/Y',
          lang: 'en',
          timepicker: !!self.time,
          datepicker: !!!self.time,
          closeOnDateSelect: false,
          theme: self.theme,
          todayButton: true,
          onChangeDateTime: function(dp,
  $input) {
            if (!dp) {
              return;
            }
            return self.$apply(function() {
              $cookies.put("app_time",
  moment(dp).format('DD/MM/YYYY kk:mm'));
              return root.$broadcast('fetchTime');
            });
          },
          onShow: function(ct) {
            var dt;
            dt = getTime();
            return this.setOptions({
              defaultDate: moment(dt,
  'DD/MM/YYYY kk:mm').format('DD/MM/YYYY'),
              defaultTime: moment(dt,
  'DD/MM/YYYY kk:mm').format('kk:mm')
            });
          }
        });
      }
    };
  }
]);
